import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  Icon,
  Radio,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ValorsBtn from "./Valors";
import { useRef, useState, useEffect } from "react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import genericImg from "../assets/DetPord.png"
import Carousel from "../Landing/MasVendidos";
import AddToCartButton from "./AddCartButton";
import ResponsiveNav from "../Landing/ResponsiveNav";
import Quality from "../assets/insignias/QualityInsg.png";
import Quality1 from "../assets/insignias/QualityInsg2.png";
import Quality2 from "../assets/insignias/QualityInsg3.png";

export default function DetalleProducto() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { articulos, pedidoActual, user } = useSelector((state) => state);
  const articulo = articulos.find((art) => art.id === parseInt(id));
  // const oferta = categories.filter((cat) => cat.id === 1);
  const scrollRef = useRef(null);
  const [selectedValor, setSelectedValor] = useState(null);
  const [cantidad, setCantidad] = useState(1); // Estado para la cantidad
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  useEffect(() => {
    updateArrowVisibility();
  }, []);

  useEffect(() => {
    if (selectedValor) {
      const existingItem = pedidoActual?.attributes?.pedido_articulos?.find(
        (item) =>
          item.articleId === articulo.id && item.valorId === selectedValor.id
      );
      setCantidad(existingItem ? existingItem.quantity : 1);
    }
  }, [selectedValor, articulo, pedidoActual]);

  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
      updateArrowVisibility();
    }
  };

  const updateArrowVisibility = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  // const handleValorSelect = (valor) => {
  //   setSelectedValor(valor);
  //   const existingItem = pedidoActual?.attributes?.pedido_articulos?.find(
  //     (item) => item.articleId === articulo.id && item.valorId === valor.id
  //   );
  //   setCantidad(existingItem ? existingItem.quantity : 0);
  // };

  // const handleQuantityChange = (newQuantity) => {
  //   setCantidad(newQuantity);
  //   if (selectedValor && articulo) {
  //     dispatch(addToCart({
  //       articleId: articulo.id,
  //       name: articulo.nombre,
  //       price: articulo.precioKG,
  //       quantity: newQuantity,
  //       valor: selectedValor.attributes.nombre,
  //       valorId: selectedValor.id,
  //       descuento:articulo?.DescPorciento,
  //       precioFinal: articulo.precioKG * (selectedValor.attributes.GrsPorcion / 1000),
  //     }));
  //   }
  // };

  const incrementarCantidad = () => setCantidad(cantidad + 1);
  const decrementarCantidad = () => {
    if (cantidad > 0) {
      const newQuantity = cantidad - 1;
      if (newQuantity === 0) {
        setCantidad(newQuantity); // Eliminar del carrito si la cantidad llega a 0
      } else {
        setCantidad(newQuantity);
      }
    }
  };

  const formatPrice = (price) => {
    if (typeof price !== "number") return price;
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const calculateDiscountedPrice = (price, discount) => {
    return price * (1 - discount / 100);
  };
  const getInsigniaImage = (id) => {
    switch (id) {
      case 1:
        return Quality;
      case 2:
        return Quality1;
      case 3:
        return Quality2;
      default:
        return null;
    }
  };







  return (
    <div>
      <ResponsiveNav showBackButton={true} />
      <Container maxW="7xl" style={{paddingTop:"80px"}}>
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 4, md: 10 }}
          py={{ base: 18, md: 24 }}
        >
          <Flex position="relative">
          <Image
      rounded="12px"
      alt="product image"
      src={genericImg}
      fit="cover"
      align="center"
      w="100%"
      position="relative"
    />
    {/* Mostrar insignias a la izquierda */}
    <VStack
      position="absolute"
      top="15px"
      left="15px"
      spacing={2}
      alignItems="flex-start"
    >
      {articulo?.insignias?.data.map((insignia) => {
        const insigniaImage = getInsigniaImage(insignia.id);
        return insigniaImage ? (
          <Image
            key={insignia.id}
            src={insigniaImage}
            alt={`Insignia ${insignia.id}`}
            width="44px"
            height="44px"
          />
        ) : null;
      })}
    </VStack>
    {/* Mostrar descuento a la derecha */}
    {articulo?.DescPorciento != null && articulo?.DescPorciento !== 0 && (
      <Box
        position="absolute"
        top="15px"
        right="15px"
        bg="#318215"
        color="white"
        px="2"
        py="1"
        borderRadius="12px"
        fontSize="sm"
        fontWeight="bold"
        zIndex="1"
      >
        -{articulo.DescPorciento}%
      </Box>
    )}
    <Box
      position="absolute"
      bottom="0"
      left="0"
      right="0"
      padding="24px 0 20px 24px"
      background="linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%)"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      borderRadius="12px"
    >
      <Text
        color="white"
        fontSize="2rem"
        textAlign="left"
        className="titles"
      >
        {articulo?.nombre}
      </Text>
            </Box>
          </Flex>
          <Stack spacing={{ base: 2, md: 10 }}>
            <Box as="header">
              {articulo?.DescPorciento != null &&
              articulo?.DescPorciento !== 0 ? (
                <VStack gap={0} alignItems="flex-start" flexDir="column-reverse">
                  <Text
                    color="#318215"
                    fontWeight="bold"
                    fontSize="32px"
                    textAlign="left"
                  >
                    $
                    {formatPrice(
                      calculateDiscountedPrice(
                        Number(articulo?.precioKG),
                        articulo?.DescPorciento
                      )
                    )}{" "}
                    /Kg
                  </Text>
                  <Text as="s" color={"gray.400"} fontSize="l" textAlign="left">
                    ${formatPrice(Number(articulo?.precioKG))} /Kg
                  </Text>
                </VStack>
              ) : (
                <Text
                  color={"gray.900"}
                  fontWeight="bold"
                  fontSize="32px"
                  textAlign="left"
                >
                  ${formatPrice(Number(articulo?.precioKG))} /Kg
                </Text>
              )}
            </Box>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction="column"
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                />
              }
            >
              <VStack spacing={{ sm: 6 }} alignItems="flex-start">
                <Text
                  color="black"
                  fontSize="1rem"
                  fontWeight="400"
                  maxW="100%"
                  whiteSpace="pre-wrap"
                  wordBreak="break-word"
                  
                >
                  {articulo?.detalle?.length < 36 ||
                  articulo?.detalle?.length === null ? (
                    <Text textAlign={"left"}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut et massa mi. Aliquam in hendrerit urna. Pellentesque
                      sit amet sapien fringilla, mattis ligula consectetur,
                      ultrices mauris. Maecenas vitae mattis tellus. Nullam quis
                      imperdiet augue.
                    </Text>
                  ) : (
                    <Text textAlign={"left"}>{articulo?.detalle}</Text>
                  )}
                </Text>
                <Box position="relative" maxW="100%" mt={4} mb={4}>
                  <Text
                    textAlign="left"
                    fontWeight="bold"
                    paddingBottom=".5rem"
                    
                  >
                    Seleccioná opción
                  </Text>
                  <Flex
              
                    overflow="hidden"
              
                  >
                    {showLeftArrow && (
                      <Button
                        onClick={() => scroll(-200)}
                        position="absolute"
                        left="0"
                        zIndex={1}
                        bg="white"
                        _hover={{ bg: "gray.100" }}
                        borderRadius="full"
                        size="sm"
                        boxShadow="md"
                      >
                        <Icon as={ArrowBackIcon} boxSize={4} color="gray.500" />
                      </Button>
                    )}
                    <Flex
                      ref={scrollRef}
                      overflowX="scroll"
                      scrollBehavior="smooth"
                      css={{
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        scrollbarWidth: "none",
                        "-ms-overflow-style": "none",
                      }}
                      gap={4}
                      maxW="100%"
                      padding={".2rem"}
                    >
                      {articulo?.valors?.data?.map((valor) => (
                        // Dentro del renderizado de tus botones
                        <ValorsBtn
                          valor={valor}
                          isSelected={selectedValor?.id === valor.id}
                          onSelect={() => setSelectedValor(valor)}
                        />
                      ))}
                    </Flex>
                    {showRightArrow && (
                      <Button
                        onClick={() => scroll(200)}
                        position="absolute"
                        right="0"
                        zIndex={1}
                        bg="white"
                        _hover={{ bg: "gray.100" }}
                        borderRadius="full"
                        size="sm"
                        boxShadow="md"
                      >
                        <Icon
                          as={ArrowForwardIcon}
                          boxSize={4}
                          color="gray.500"
                        />
                      </Button>
                    )}
                  </Flex>
                </Box>
              </VStack>

              <Carousel />
            </Stack>
          </Stack>
        </SimpleGrid>
        {selectedValor && (
          <Box
            position="sticky"
            bottom="0"
            width="100%"
            bg="white"
            p="1rem 0"
            zIndex={2}
            textAlign="center"
            display={"flex"}
            gap={"1rem"}
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              borderRadius="full"
              width="200px"
              height="50px"
              bg="black"
              color="white"
              overflow="hidden"
            >
              <Button
                onClick={decrementarCantidad}
                bg="black"
                color="white"
                borderRadius="none"
                height="100%"
                width="33%"
                _hover={{ bg: "gray.800" }}
                _active={{ bg: "gray.700" }}
              >
                -
              </Button>
              <Box
                width="34%"
                bg="white"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="solid black 2px"
              >
                <Text fontSize="lg" color="black" fontWeight="bold">
                  {cantidad}
                </Text>
              </Box>
              <Button
                onClick={incrementarCantidad}
                bg="black"
                color="white"
                borderRadius="none"
                height="100%"
                width="33%"
                _hover={{ bg: "gray.800" }}
                _active={{ bg: "gray.700" }}
              >
                +
              </Button>
            </Flex>

            <AddToCartButton
              articulo={articulo}
              selectedValor={selectedValor}
              cantidad={cantidad}
            />
          </Box>
        )}
      </Container>
    </div>
  );
}
