import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Button, Flex, Text, VStack, Box } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';

const MobileAppBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    
    window.addEventListener('beforeinstallprompt', handler);

    const timer = setTimeout(() => {
      if (!localStorage.getItem('bannerShown')) {
        setIsOpen(true);
        localStorage.setItem('bannerShown', 'true');
      }
    }, 5000);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
      clearTimeout(timer);
    };
  }, []);

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Usuario aceptó la instalación de la PWA');
        }
        setDeferredPrompt(null);
        setIsOpen(false);
      });
    }
  };

  const MarcaAgua = (
    <svg width="100" height="157" viewBox="0 0 220 277" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <path d="M66.8627 67.3569C67.7255 73.4132 22.6471 95.1149 0 105.209C14.2353 87.0398 45.8333 72.4038 59.8529 67.3569C41.7353 64.0958 33.6111 40.7636 31.8137 29.5052C53.8137 19.2561 85.5556 30.6698 98.6765 37.6578C114.853 21.3524 159.608 -5.43502 162.304 0.970671C165 7.37636 153.676 44.0636 139.118 46.3929C143.971 49.3046 162.843 64.4453 191.422 107.538C172.549 95.3091 145.768 62.1159 135.343 45.8105C150.441 38.2401 152.598 12.0349 153.676 9.12319C154.755 6.21152 102.99 39.9871 100.294 42.3165C97.598 44.6458 43.1373 22.5171 42.0588 36.4931C40.9804 50.4692 65.7843 59.7865 66.8627 67.3569Z" fill="#59452C"/>
        <path d="M131.029 276.998C122.402 277.464 52.6634 207.312 18.8725 172.177C36.1274 177.302 100.114 238.758 129.951 268.845C127.255 264.186 138.039 196.635 140.735 190.23C143.431 183.824 206.52 184.989 209.216 181.495C211.373 178.7 217.304 174.118 220 172.177C219.281 175.089 216.873 182.077 212.99 186.736C208.137 192.559 156.912 191.977 148.284 195.471C139.657 198.965 141.814 276.415 131.029 276.998Z" fill="#59452C"/>
      </g>
    </svg>
  );

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent border="#CA0017 solid 2px" position="relative" overflow="hidden">
        <Box position="absolute" top="50%" left="15%" transform="translate(-50%, -50%)" zIndex={0}>
          {MarcaAgua}
        </Box> <Box position="absolute" top="50%" right="-10%" transform="translate(-50%, -50%)" zIndex={0}>
          {MarcaAgua}
        </Box>
        <ModalCloseButton zIndex={2} />
        <ModalBody zIndex={1} position="relative">
          <VStack padding=".5rem" borderRadius="24px" m={4}>
            <div>
              <Text fontWeight="bold">¿Quieres una mejor experiencia?</Text>
              <Text fontWeight="bold">Instala nuestra app DeTernera</Text>
            </div>
            <Flex justifyContent="space-between" gap="2rem">
              <Button
                color="white"
                onClick={handleInstall}
                bg="#CA0017"
                borderRadius="24px"
              >
                Instalar App
              </Button>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MobileAppBanner;