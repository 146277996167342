import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGoogleCallback } from '../../Redux/Slice';

const GoogleCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (code) {
      dispatch(handleGoogleCallback(code))
        .unwrap()
        .then(() => {
          // Redirigir al usuario a la página principal o dashboard después de un login exitoso
          navigate('/');
        })
        .catch((error) => {
          console.error('Error during Google login:', error);
          // Manejar el error, tal vez redirigir a una página de error
          navigate('/login', { state: { error: 'Failed to login with Google' } });
        });
    }
  }, [dispatch, location, navigate]);

  return <div>Processing Google login...</div>;
};

export default GoogleCallback;