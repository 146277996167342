import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Landing from "./Landing/Landing";
import SimpleCard from "./Perfil/Autenticacion/LogIn";
import SignupCard from "./Perfil/Autenticacion/SignUp";
import Exito from "./Results/Exito";
import Error from "./Results/Error";
import NotFound from "./Results/NotFound";
import UserProfile from "./Perfil/Perfil";
import EditUser from "./Results/EditUser";
import Ayuda from "./Results/Ayuda";
import Layout from "./Results/layout";
import CatalogoFull from "./Catalogo/CatalogoFull";
import PersonalDataForm from "./Perfil/PersonalData";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchValor } from "./Redux/Slice";
import DetalleProducto from "./Catalogo/ProductDetail";
import Pedidos from "./Perfil/Pedidos";
import Carrito from "./Carrito/Carrito";
import PedidoDetalle from "./Perfil/PedidoDetalle";
import MobileAppBanner from "./Results/MobileAppBanner";
import WhatsAppButton from "./Results/ButtonWssp";
import LoginCard from "./Perfil/Autenticacion/LogIn";
import GoogleCallback from "./Perfil/Autenticacion/GoogleCallback";
import PrivacyPolicy from "./Perfil/Autenticacion/PrivacyPolicy";
import EmailConfirmation from "./Perfil/Autenticacion/EmailConfirmation";


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchValor())
  }, [dispatch]);

  const categories = useSelector(state => state.categories);

  return (
    <Router>
      <div className="App">
        <MobileAppBanner/>
        <WhatsAppButton phoneNumber="+5491140680612" /> {/* Reemplaza con el número de WhatsApp correcto */}
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Login" element={<SimpleCard />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route path="/confirmar-email" element={<EmailConfirmation />} />
          <Route path="/Signup" element={<SignupCard />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Exito/:id" element={<Exito />} />
          <Route path="/error" element={<Error />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/UserProfile" element={<UserProfile />} />
          <Route path="/Pedidos" element={<Pedidos />} />
          <Route path="/Carrito" element={<Carrito />} />
          <Route path="/Pedidos/:id?" element={<PedidoDetalle />} />
          <Route path="/EditUser" element={<EditUser />} />
          <Route path="/Ayuda" element={<Layout child={<Ayuda />} />} />
          <Route path="/ProdDetalle/:id" element={<DetalleProducto />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route
            path="/CatFull"
            element={<Layout child={<CatalogoFull />} />}
          />
          <Route path="/PersonalData" element={<PersonalDataForm />} />
          
          {/* Rutas dinámicas para categorías */}
          {categories && categories.map(categoria => (
            <Route 
              key={categoria.id}
              path={`/${categoria.nombre}`} 
              element={<Layout child={<CatalogoFull categori={categoria}/>} />} 
            />
          ))}
          
          {/* Ruta para redirigir a NotFound si la URL no coincide con ninguna ruta definida */}
          <Route path="*" element={<Navigate to="/notfound" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;