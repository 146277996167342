import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { Navigate, Link as RouterLink } from "react-router-dom";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Button,
  Text,
  Link,
  useToast,
  Container,
  InputGroup,
  InputRightElement,
  Divider,
  Img,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { loginUser, handleGoogleCallback } from "../../Redux/Slice";
import BlackBox from "../../Landing/InfoTopBox";
import ResponsiveNav from "../../Landing/ResponsiveNav";
import Gmail from "../../assets/Glogo.png"
import GoogleAuthButton from "./GoogleButton";


export default function LoginCard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, status, error } = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const toast = useToast();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    
    if (code) {
      dispatch(handleGoogleCallback(code))
        .unwrap()
        .then(() => {
          toast({
            title: "Inicio de sesión con Google exitoso",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          navigate('/');
        })
        .catch((err) => {
          toast({
            title: "Error en el inicio de sesión con Google",
            description: err.message || "Ocurrió un error",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  }, [dispatch, location, navigate, toast]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(loginUser({ email, password })).unwrap();
      toast({
        title: "Inicio de sesión exitoso",
        status: "success",
        duration: 3000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
          // O si prefieres padding:
          // paddingBottom: "60px",
        },
      });
      setLoginSuccess(true);
    } catch (err) {
      toast({
        title: "Error en el inicio de sesión",
        description: error || "Ocurrió un error",
        status: "error",
        duration: 3000,
        isClosable: true,
        containerStyle: {
          marginBottom: "80px",
          // O si prefieres padding:
          // paddingBottom: "60px",
        },
      });
    }
  };

  const handleGoogleLogin = () => {
    dispatch(handleGoogleCallback());
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    
    if (code) {
      dispatch(handleGoogleCallback(code))
        .unwrap()
        .then(() => {
          toast({
            title: "Inicio de sesión con Google exitoso",
            status: "success",
            duration: 3000,
            isClosable: true,
            containerStyle: {
              marginBottom: "80px",
              // O si prefieres padding:
              // paddingBottom: "60px",
            },
          });
          navigate('/');
        })
        .catch((err) => {
          toast({
            title: "Error en el inicio de sesión con Google",
            description: err.message || "Ocurrió un error",
            status: "error",
            duration: 3000,
            isClosable: true,
            containerStyle: {
              marginBottom: "80px",
              // O si prefieres padding:
              // paddingBottom: "60px",
            },
          });
        });
    }
  }, [dispatch, location, navigate, toast]);
  if (loginSuccess) {
    return <Navigate to="/" />;
  }
 
  

  return (
    <div style={{marginTop:"80px", paddingBottom:"100px"}}>
      <ResponsiveNav />
      <VStack spacing={6} align="stretch" padding={"1rem"}>
        <BlackBox
          showBackButton={true}
          info={"Inicia sesión para disfrutar al máximo el servicio."}
          titulo={"Iniciar Sesión"}
        />
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch" maxW="500px" margin="auto">
            <FormControl id="email" isRequired>
              <FormLabel>Dirección de correo electrónico</FormLabel>
              <Input
                style={styles.Input}
                type="email"
                placeholder="nombre@ejemplo.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Contraseña</FormLabel>
              <InputGroup>
                <Input
                  style={styles.Input}
                  type={showPassword ? "text" : "password"}
                  placeholder="Tu contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    bg={"none"}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              type="submit"
              colorScheme="red"
              size="lg"
              width="full"
              isLoading={status === "loading"}
              style={styles.button}
            >
              Acceder
            </Button>
          </VStack>
        </form>
        {/* <Button
          onClick={handleGoogleLogin}
          colorScheme="blue"
          size="lg"
          width="full"
          style={styles.googleButton}
          borderRadius={"24px"}
          maxW={"500px"}
          margin={"auto"}
        >
          Iniciar sesión con Google <Box borderRadius="50%" bg={"white"} p={"4px"} marginLeft={".5rem"}><Img src={Gmail} alt="Logo Google auth"/></Box> 
        </Button> */}
        <GoogleAuthButton/>
        <Divider />
        <Text align="center">
          ¿No tienes cuenta?{" "}
          <Link as={RouterLink} to="/signup" color="red.500"     id="redirRegistroBoton">
            Regístrate
          </Link>
        </Text>
      </VStack>
    </div>
  );
}

const styles = {
  Input: {
    borderRadius: "24px",
    backgroundColor: "#F2F2F2",
    width: "100%",
  },
  button: {
    borderRadius: "24px",
    backgroundColor: "#CA0017",
    color: "white",
    width: "100%",
    padding: "12px 24px",
  },
};
