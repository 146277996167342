import React from "react";
import { Box, Text, Button, Flex, VStack, Image } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import carne from "../assets/CarnePro.png";
import "../Landing/Landing.css";
import Quality from "../assets/insignias/QualityInsg.png";
import Quality1 from "../assets/insignias/QualityInsg2.png";
import Quality2 from "../assets/insignias/QualityInsg3.png";

const URL_BASE = process.env.REACT_APP_API_BASE;

const ProductCard = ({ product, Categoria, SubCat }) => {
  const formatPrice = (price) => {
    if (typeof price !== "number") return price;
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const calculateDiscountedPrice = (price, discount) => {
    return price * (1 - discount / 100);
  };

  const noSelectStyle = {
    userselect: "none",
    webkituserselect: "none",
    mozuserselect: "none",
    msuserselect: "none",
  };

  // Función para obtener la imagen de insignia correspondiente al ID
  const getInsigniaImage = (id) => {
    switch (id) {
      case 1:
        return Quality;
      case 2:
        return Quality1;
      case 3:
        return Quality2;
      default:
        return null;
    }
  };

  return (
    <Box
      as={NavLink}
      to={`/ProdDetalle/${product?.id}`}
      className="card"
      position="relative"
      {...noSelectStyle}
       id="irProductuCard"
    >
      <VStack spacing={0} alignItems={"flex-start"}>
        {product?.DescPorciento != null && product?.DescPorciento !== 0 && (
          <Box
            position="absolute"
            top="16px"
            right="16px"
            bg="#318215"
            color="white"
            px="1"
            py=".5"
            borderRadius="md"
            fontSize="sm"
            fontWeight="bold"
            zIndex="1"
          >
            -{product?.DescPorciento}%
          </Box>
        )}
        <Box className="image_container">
          <Image
            src={carne}
            alt="carne"
            width="100%"
            height="100%"
            objectFit="fill"
          />
          <VStack
            position="absolute"
            top="10px"
            left="10px"
            spacing={2}
            alignItems="flex-start"
          >
            {/* Insignias dinámicas */}
            {product?.insignias?.data.map((insignia) => {
              const insigniaImage = getInsigniaImage(insignia.id);
              return insigniaImage ? (
                <Image
                  key={insignia.id}
                  src={insigniaImage}
                  alt={`Insignia ${insignia.id}`}
                  width="24px"
                  height="24px"
                />
              ) : null;
            })}
          </VStack>
        </Box>
        <Box>
          <Text fontSize="12px" color="#767676">
            {Categoria ? Categoria.replace(/Productos en|Productos/g, "") + "/" : ""}{SubCat||"texto subcat"}
          </Text>
        </Box>
        <Box className="title" height="42px" overflow="hidden">
          <Text
            marginBottom=".25rem"
            noOfLines={2}
            overflow="hidden"
            textOverflow="ellipsis"
            className="titles"
          >
            {product?.nombre}
          </Text>
        </Box>
        <Box className="price">
          {product?.DescPorciento != null && product?.DescPorciento !== 0 ? (
            <VStack alignItems="left" gap={0}>
              <Text as="s" fontSize="10px" color="gray.500" fontWeight="400">
                ${formatPrice(Number(product?.precioKG))} /Kg
              </Text>
              <Text color="#318215" fontWeight="bold">
                $
                {formatPrice(
                  calculateDiscountedPrice(
                    Number(product?.precioKG),
                    product?.DescPorciento
                  )
                )}{" "}
                /Kg
              </Text>
            </VStack>
          ) : (
            <Text>${formatPrice(Number(product?.precioKG))} /Kg</Text>
          )}
        </Box>
      </VStack>
      <Flex className="action">
        <Button
          as={NavLink}
          to={`/ProdDetalle/${product?.id}`}
          bgColor="#CA0017"
          color="white"
          width="100%"
          borderRadius="24px"
          height="35px"
          id="irProductuBoton"
        >
          Ir al producto
        </Button>
      </Flex>
    </Box>
  );
};

export default ProductCard;